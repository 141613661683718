<template>
  <div id="inicio">
    <vue-headful :title="title" />
    <dominio-resumen-component class="no-margin"/>
    <bienvenido-nic  />
    <!-- Título -->
    <v-row justify="center">
      <v-col class="text-center" cols="12">
        <h1 id="ventajas-title" class="display-1 text-uppercase text-center italic-text">
          VENTAJAS DE TENER UN DOMINIO .NI
        </h1>
      </v-col>
    </v-row>

    <!-- Contenedor de las ventajas -->
    <v-row no-gutters justify="center" class="barril-container">
      <!-- Primer par de ventajas (a la par) -->
      <v-col sm="12" md="5" class="barril-section">
        <div class="barril">
          <!-- Imagen dentro del div.barril -->
          <v-img
          src="@/assets/img/proteger.png"
            alt="Imagen de protección"
            height="150px"
            width=" auto"
            class="mb-2"
            contain
          ></v-img>
          
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green" v-text="'mdi-checkbox-marked-circle'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-action-text class="ventaja">
                  Protección de tu nombre personal, el de tu institución o negocio y obtienes un nombre fácil de recordar para utilizarlo en Internet a nivel internacional.
                </v-list-item-action-text>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>

      <!-- Segundo par de ventajas (a la par) -->
      <v-col sm="12" md="5" class="barril-section">
        <div class="barril">
          <!-- Imagen dentro del div.barril -->
          <v-img
            src="@/assets/img/internet.png"
            alt="Imagen de protección"
            height="150px"
            width=" auto"
            class="mb-2"
            contain
          ></v-img>
          
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green" v-text="'mdi-checkbox-marked-circle'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-action-text class="ventaja">
                  Tu nombre, el de tu institución o tu negocio se posiciona geográficamente en internet, vinculando tu presencia a Nicaragua y haciéndola visible a nivel mundial.
                </v-list-item-action-text>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <!-- Sección de los dos últimos textos (uno debajo del otro) -->
    <v-row no-gutters justify="center" class="barril-container">
      <!-- Tercer texto -->
      <v-col sm="12" md="5" class="barril-section">
        <div class="barril">
          <!-- Imagen dentro del div.barril -->
          <v-img
            src="@/assets/img/red.png"
            alt="Imagen de protección"
            height="150px"
            width=" auto"
            class="mb-2"
            contain
          ></v-img>
          
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green" v-text="'mdi-checkbox-marked-circle'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-action-text class="ventaja">
                  Tu sitio web es visible a nivel mundial www.nombredemiempresa.com.ni
                </v-list-item-action-text>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>

      <!-- Cuarto texto -->
      <v-col sm="12" md="5" class="barril-section">
        <div class="barril">
          <!-- Imagen dentro del div.barril -->
          <v-img
          src="@/assets/img/correo.png"
            alt="Imagen de protección"
            height="150px"
            width=" auto"
            class="mb-2"
            contain
          ></v-img>
          
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green" v-text="'mdi-checkbox-marked-circle'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-action-text class="ventaja">
                  Tus correos electrónicos serán institucionalizados o personalizados.
                </v-list-item-action-text>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <card-phone class="phone" />
    <parnerts />
  </div>
</template>


<script>
import vueHeadful from "vue-headful";

export default {
  components: {
    vueHeadful,
    CardPhone: () => import("@/components/CardPhone"),
    Parnerts: () => import("@/components/Parnerts"),
    BienvenidoNic: () => import("@/components/BienvenidoNic"),
    DominioResumenComponent: () =>
      import("@/components/DominioResumenComponent"),
  },

  data: () => ({
    title: "Inicio",
    action: "route",
    // Datos de la sección de ventajas
    ventajas: [
      {
        text: "VENTAJAS DE TENER UN DOMINIO .NI",
      },
    ],
    // Datos de la sección de requisitos
    requisitos: [
      {
        text:
          "Protección de tu nombre personal o el de tu institución y obtienes un nombre fácil de recordar para utilizarlo en Internet.",
        icon: "mdi-checkbox-marked-circle",
      },
      {
        text:
          "Tu nombre o el de tu institución se geo posicionan en internet con Nicaragua.",
        icon: "mdi-checkbox-marked-circle",
      },
      {
        text:
          "Tu sitio web es visible a nivel mundial www.nombredemiempresa.com.ni",
        icon: "mdi-checkbox-marked-circle",
      },
      {
        text:
          "Tus correos electrónicos serán institucionalizados o personalizados.",
        icon: "mdi-checkbox-marked-circle",
      },
    ],
  }),
};
</script>
<style scoped>
.ventaja{
  font-size: 20px; /* Tamaño de fuente */
  text-align: justify; /* Justifica el texto */
  line-height: 1.6; /* Espaciado entre líneas para mejorar la legibilidad */
  color: #333; /* Color oscuro para mejor contraste y lectura */
  font-family: 'Arial', sans-serif; /* Tipo de fuente más legible */
  font-weight: 400; /* Peso normal para el texto */
  margin-bottom: 10px; /* Espacio abajo del texto */
  padding: 0 10px; /* Espaciado a los lados */
  text-justify: inter-word; /* Asegura que el espacio entre palabras sea más uniforme */
}

.top {
  margin-top: -0px !important;
}

@media (max-width: 725px) {
  .top {
    margin-top: -120px !important;
  }
}

#h1paralax {
  margin-top: 40px !important;
  font-weight: 600 !important;
}

.v-main .img-top {
  margin-top: 40px;
}

#parallax-front {
  margin-top: 60px;
}

.parallax-front {
  font-size: 16px !important;
}

.v-application p {
  padding: 10px;
}

@media (max-width: 725px) {
  #paralax-front {
    margin-top: 30px;
    font-size: 16px;
    padding: 10px;
  }

  #parallax-front {
    margin-top: -20px;
  }

  .h1paralax {
    margin-top: 10px;
  }
}

@media (min-width: 725px) {
  .phone {
    display: none !important;
  }

  .parallax-front {
    font-size: 14px !important;
  }
}

/* Estilo para el contenedor "barril" */
.barril-container {
  margin-top: 40px;
}

.barril-section {
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0; /* Fondo ligeramente gris */
}

.barril {
  padding: 20px;
  border-radius: 25px;
  background-color: #ffffff; /* Fondo blanco */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

.barril-section .v-list-item {
  margin-bottom: 10px;
}

.italic-text {
  font-family: 'Times New Roman', serif; /* Fuente base estándar */
  font-size: 1rem; /* Tamaño de la fuente */
  font-style: italic; /* Estilo cursivo */
  font-weight: 400; /* Peso normal de la fuente */
  text-transform: uppercase; /* Texto en mayúsculas */
  letter-spacing: 2px; /* Espaciado entre letras */
  color: #2c3e50; /* Color oscuro para el texto */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3), 0 0 25px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
}
.no-margin {
  margin: 25 !important;
  padding: 0 !important;
}

</style>
